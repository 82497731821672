.Home .Title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 0.6;
}

.Home .Title h1 {
  font-size: 3rem;
  font-weight: 800;
}

.Home .Title p {
  font-size: 1rem;
  font-weight: 500;
  font-family: var(--font2);
}

.Home .Bubble {
  width: 25rem;
  height: 25rem;
  background-color: var(--accentColor3);
  border-radius: 50%;
  position: absolute;
  bottom: -30%;
  right: -15%;
}

@media screen and (max-width: 946px) {
  .Home .Title {
    line-height: normal;
  }
}

@media screen and (max-width: 720px) {
  .Home .Bubble {
    width: 15rem;
    height: 15rem;
  }
}
