:root {
  --font: "Inter", sans-serif;
  --font2: "Open Sans", sans-serif;
  --background: #121212;
  --accentColor: rgb(114, 137, 218);
  --accentColor2: rgb(114, 137, 218, 0.75);
  --accentColor3: rgb(114, 137, 218, 0.5);
  --accentColor4: rgb(114, 137, 218, 0.25);
  --text: #ffffff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, var(--font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background);
  color: var(--text);
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  display: inline-block;
  background-color: var(--accentColor);
  color: white;
  outline: none;
  border: none;
  border-radius: 7px;
  padding: 0.5rem 1rem;
  font-family: var(--open-sans);
  font-size: 0.85rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 250ms ease, box-shadow 250ms ease;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: var(--accentColor3);
  cursor: not-allowed;
}

button:hover,
button:active {
  background-color: var(--accentColor2);
}

button a {
  text-decoration: none;
  color: inherit;
}
